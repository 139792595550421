
html {
  display: flex;
  justify-content: center;
  background-color: #FDFBFC;
}
body {
  margin: 0;
  padding: 0;
  max-width: 1920px;
  font-family: sans-serif;
}
video {
  border-radius: 0.25rem;
}
/* .react-parallax-bgimage {
  display: none;
} */
